<template>
  <div>
    <div class="d-flex">
      <page-header class="mb-4">
        {{ $t('tableRule') }}
      </page-header>
    </div>
    <div class="d-flex flex-wrap">
      <div>
        <v-card
          class="pa-4"
          elevation="0"
          outlined
          style="border-radius: 12px"
        >
          <div class="d-flex">
            <div class="text-subtitle-1 font-weight-bold">
              {{ $t('reservationPersonCount') }}
            </div>
            <v-icon class="ml-2">
              mdi-human-male-female
            </v-icon>
          </div>
          <div class="text-caption mb-2">
            {{ $t('kidRule') }}
          </div>
          <div class="mt-8">
            <div
              style="display: grid;grid-template-columns: repeat(4,minmax(0,1fr));
            grid-gap: 8px;width: 100%"
            >
              <v-card
                v-for="n in 18"
                :key="n.id"
                class="pa-3"
                color="grey lighten-3"
                elevation="0"
                @click="searchUsefulTable(n)"
              >
                <div class="d-flex justify-center">
                  <v-icon
                    color="black"
                    left
                  >
                    mdi-human-male-girl
                  </v-icon>
                  <div>{{ n }}</div>
                </div>
              </v-card>
            </div>
          </div>
        </v-card>
      </div>
      <div
        v-if="showRuleDetail"
        :class="!$vuetify.breakpoint.lgAndUp ? 'mt-4' : 'ml-16'"
      >
        <v-card
          :width="!$vuetify.breakpoint.lgAndUp ? '100%' : '640px'"
          class="pa-4"
          elevation="0"
          outlined
          style="border-radius: 12px"
        >
          <div class="text-h4 font-weight-bold">
            {{ $t('personCountDetail') }}: {{ $t('reservationPersonCount') }} {{ adultCount }} {{ $t('Pice') }}
          </div>
          <v-divider
            class="mt-2 mb-2"
            style="color: #333333"
          />
          <div>
            <template>
              <div class="d-flex flex-wrap mt-4">
                <div>
                  <div class="mb-4">
                    {{ $t('tableSituation') }}
                  </div>
                  <div
                    v-for="(a,index) in personCountRule"
                    :key="a.key"
                    class="d-flex mb-1"
                  >
                    <v-select
                      v-model="a.tableSeatCount"
                      :items="tableTypeList"
                      :label="$t('selectTableType')"
                      :menu-props="{offsetY:true, outlined:true, contentClass:'elevation-2 ikRounded',
                                    nudgeBottom:'5px',}"
                      :suffix="$t('tableSeat')"
                      dense
                      outlined
                      prepend-icon="mdi-table-furniture"
                      style="width: 300px"
                    />
                    <div style="width: 60px">
                      <v-btn
                        :disabled="personCountRule.length===0||(!a.tableSeatCount)"
                        class="ml-1"
                        icon
                        @click="personCountRule.splice(index, 1)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <v-spacer v-if="$vuetify.breakpoint.lgAndUp"/>
                <div
                  class="d-flex flex-column"
                >
                  <div class="mb-4">
                    {{ $t('chairSituation') }}
                  </div>
                  <v-text-field
                    v-model="moveChairCount"
                    :suffix="$t('chairUnit')"
                    dense
                    disabled
                    outlined
                    prepend-icon="mdi-seat"
                    style="width: 300px"
                  />
                </div>
              </div>
            </template>
            <div class="d-flex">
              <v-spacer v-if="$vuetify.breakpoint.lgAndUp"/>
              <v-btn
                class="primary mt-4 mr-4"
                @click="save"
              >
                {{ $t('save') }}
              </v-btn>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <loading-dialog :just-wait="justWait"/>
  </div>
</template>

<script>
  import { addTableReserveRules, deleteTableReserveRules, getReserveSettings } from '@/common/Utlis/api'
  import LoadingDialog from '@/composable/LoadingDialog'
  import IKUtils from 'innerken-js-utils'
  import Table from '@/model/tableAndPerson/Table'
  import { uniqBy } from 'lodash'
  import { showSuccessMessage } from '@/common/utils'
  import PageHeader from '@/composable/InK/typography/PageHeader'

  let counter = 0
  const defaultRules = {
    tableSeatCount: '',
    additionalChairCount: '0',
  }

  export default {
    name: 'TableRules',
    components: {
      LoadingDialog,
      PageHeader,
    },
    data: () => {
      return ({
        showRuleDetail: false,
        oldPersonCountRule: null,
        moveChairCount: null,
        tableTypeList: [],
        personCountRule: false,
        isLoading: null,
        tableSeat: null,
        justWait: false,
        reserveSetting: null,
        adultCount: 1,
      })
    },
    watch: {
      personCountRule: {
        deep: true,
        handler (val) {
          const emptyNode = val.filter(it => !it.tableSeatCount).length
          if (emptyNode === 0) {
            this.personCountRule.push(this.createDefaultAttribute())
          }
          const res = val.map(it => parseInt(it.tableSeatCount))
          res.pop()
          if (val.length < 2) {
            this.moveChairCount = 0
          } else {
            this.moveChairCount = (this.adultCount - res.reduce((a, b) => a + b))
          }
        },
      },
    },
    mounted () {
      this.reloadData()
    },
    methods: {
      async save () {
        this.justWait = true
        for (const item of this.oldPersonCountRule) {
          await deleteTableReserveRules(item.id)
        }
        this.personCountRule[0].additionalChairCount = this.moveChairCount
        for (const item of this.personCountRule) {
          if (item.tableSeatCount !== '') {
            await addTableReserveRules({
              personCount: this.adultCount,
              tableSeatCount: item.tableSeatCount,
              additionalChairCount: item.additionalChairCount,
            })
          }
        }
        this.justWait = false
        showSuccessMessage(this.$t('save_success'))
        await this.reloadData()
        this.searchUsefulTable(this.adultCount)
      },
      createDefaultAttribute () {
        const obj = IKUtils.deepCopy(defaultRules)
        obj.key = counter++
        return obj
      },
      searchUsefulTable (n) {
        this.adultCount = n
        this.showRuleDetail = true
        this.oldPersonCountRule = this.reserveSetting.personTableRules.filter(it => parseInt(it.personCount) === n)
        this.personCountRule = this.reserveSetting.personTableRules.filter(it => parseInt(it.personCount) === n)
      },
      async reloadData () {
        this.reserveSetting = (await getReserveSettings())
        // const res = new Set((await getReserveTable()).map(it => parseInt(it.id)))
        // this.tableList = (await Table.getList(true)).map(x => {
        //   x.ifReserve = res.has(x.id)
        //   return x
        // }).filter(it => it.ifReserve)
        this.tableList = (await Table.getList(true))
        console.log(this.tableList, 'list')
        this.tableTypeList = uniqBy(this.tableList.map(it => it.seatCount)).sort(function (a, b) {
          return a - b
        })
      },
    },
  }
</script>

<style scoped>

</style>
